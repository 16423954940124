<template>
    <main class="rainwater main">
        <h1>Ливневая канализация</h1>
        <div class="imgs">
            <img v-for="(src, index) in images" :src="src" :key="index" alt="Ливневая канализация" class="img"
                @click="openModal(index)" />
        </div>

        <div v-if="isModalOpen" class="modal" @click="closeModal">
            <div class="modal-content" @click.stop>
                <button v-if="currentIndex > 0" class="prev" @click="prevImage">‹</button>
                <img :src="images[currentIndex]" alt="Enlarged image" class="zoomable-img" ref="zoomImg"
                    @wheel="zoomImage" />
                <button v-if="currentIndex < images.length - 1" class="next" @click="nextImage">›</button>
            </div>
        </div>

        <div class="content">
            <div class="contents content1">
                <h2>
                    Ливневые канализации – тип, основные элементы
                </h2>
                <ul>
                    Можно применять несколько вариантов ливнеотводов:
                    <li>открытый – недорогой вариант устройства системы желобов,
                        защищенных металлической решеткой, расположенных под уклоном, по ним вода отводится от дома,
                        беседок и веранд, дорожек;</li>
                    <li>закрытого типа – система состоит из закрытых труб, колодцев,
                        пескоуловителя, нескольких ступеней очистки и смотровых колодцев</li>
                    <li>иногда достаточно установить ливневый дренаж,
                        который отводит ливневые воды от фундамента дома, дорожек, площадок и беседок;</li>
                    <li>
                        комбинированная – позволяет эффективно сочетать закрытую и открытую ливневку,
                        ориентируясь на особенности загородного участка (рельеф, состав почв, площадь, геометрия).
                    </li>
                    <p>При первом выезде инженер-конструктор должен выполнить все
                        необходимые замеры и вычисления для составления проекта канализации
                        и дренажа участка с оптимальными параметрами.
                    </p>
                </ul>
                <h2>Элементы ливневой канализации</h2>
                <ul>
                    Элементы конструкции,
                    которые необходимы для устройства современной ливневой канализации:
                    <br />
                    <li> водостоки – система желобов для отвода воды с кровли;</li>
                    <li> дождеприемники – пластиковые или металлические воронки, которые располагают под водосточной
                        трубой для сбора воды, стекающей с крыши;</li>
                    <li>

                        лотки для водоотвода – желоба из пластика, жести, бетона или композитных материалах, по ним вода
                        движется к приемнику коллектору;
                    </li>
                    <li>
                        защитные решетки – устанавливаются на лотки водоотвода. Предотвращают засорение открытого желоба
                        листьями и мусором;
                    </li>
                    <li>
                        пескоуловителя – фильтрующие конструкции из пластика или бетона;
                    </li>
                    <li>
                        закрытые трубы водоотвода;
                    </li>
                    <li>
                        коллекторы – емкости, в которые собирается вода.
                    </li>
                </ul>
                <p>Стоимость системы дождевой канализации во многом зависит от выбранной конструкции и материала, из
                    которого изготовлены основные узлы ливневой системы.</p>
            </div>


            <div class="contents content2">
                <h2>
                    Основные этапы работ по устройству ливневки
                </h2>
                <ul>
                    <br />
                    Правильная последовательность монтажных работ:
                    <br />
                    <li>
                        оценка характеристик участка при выезде на объект;
                    </li>
                    <li>
                        разработка проекта ливневой канализации, который учитывает:
                    </li>
                    <li>
                        среднегодовой объем ливневых вод – определяет производительность системы;
                    </li>
                    <li>
                        расчет уклона ливнестоков;
                    </li>
                    <li>
                        оптимальное расположение труб, ливнеприемников, коллекторов (план-схема);
                    </li>
                    <li>
                        комбинирование открытой и закрытой системы.
                    </li>
                    <li>
                        монтаж – разметка участка, рытье траншей и колодцев, бетонирование оснований, соединение всех
                        узлов системы.
                    </li>
                    После монтажа обязательно провести тестовые испытания на определение протечек и скорость движения
                    воды в системе.
                </ul>

                <h2>
                    Оперативный и надежный монтаж «под ключ»
                </h2>
                <p>
                    Производственная компания «Нева-Плитка» предлагает полный комплекс
                    услуг по монтажу ливневок любой степени сложности,
                    с учетом индивидуальных особенностей участка, состава грунта и финансовых возможностей заказчика:
                </p>
                <br />
                <ul>
                    <li>
                        наша компания производит и устанавливает тротуарную плитку, наши инженеры знают все тонкости
                        устройства эффективной ливневой канализации в сложных условиях Санкт-Петербурга и области;
                    </li>
                    <li>
                        устанавливаем надежные и долговечные системы отвода ливневых вод любой степени сложности – опыт
                        работы 5 лет;
                    </li>
                    <li>
                        у нас работают профессиональные бригады, поэтому мы предоставляем гарантию на все виды работ;
                    </li>
                    <li>
                        вы оплачиваете наши услуги только после выполнения всех монтажных работ;
                    </li>
                    <li>
                        работаем только с надежными строительными материалами от проверенных поставщиков;
                    </li>
                    <li>
                        предлагаем адекватные цены и гарантируем высокое качество работ.
                    </li>
                    <p>Звоните, обсудим все детали и определимся с выбором оптимальной конструкции и стоимости ливневой
                        канализации на Вашем загородном участке.
                        Общая калькуляция стоимости будет зависеть от выбранных комплектующих, размера участка и
                        необходимых земляных работ. Все вопросы можно обсудить с инженером компании.</p>
                </ul>
            </div>
        </div>

    </main>
</template>

<script>
export default {
    data() {
        return {
            isModalOpen: false,
            currentIndex: 0,
            zoomLevel: 1,
            images: [
                require('../../public/images/sistemy/knl1.png'),
                require('../../public/images/sistemy/knl2.jpg'),
                require('../../public/images/sistemy/knl3.jpg'),
                require('../../public/images/sistemy/knl4.png'),
                require('../../public/images/sistemy/knl5.png')
            ]
        };
    },
    methods: {
        openModal(index) {
            this.currentIndex = index;
            this.isModalOpen = true;
            this.zoomLevel = 1;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        zoomImage(event) {
            event.preventDefault();
            const zoomStep = 0.1;
            if (event.deltaY > 0) {
                this.zoomLevel = Math.max(this.zoomLevel - zoomStep, 1);
            } else {
                this.zoomLevel += zoomStep;
            }
            this.$refs.zoomImg.style.transform = `scale(${this.zoomLevel})`;
        },
        nextImage() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++;
                this.zoomLevel = 1;
            }
        },
        prevImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.zoomLevel = 1;
            }
        }
    }
};
</script>

<style scoped>
.rainwater{
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.rainwater h1 {
    padding-bottom: 25px;
    color: #c7c6c6;
}

.content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 25px;
    border: 2px solid #c7c6c6;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff3f3;

}

.contents {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.imgs {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.img {
    width: 300px;
    height: 200px;
    transition: box-shadow 0.5s ease;
    cursor: pointer;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.img:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.zoomable-img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease;
    cursor: zoom-in;
}

.prev,
.next {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 30px;
    transform: translateY(-50%);
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.prev:hover,
.next:hover {
    background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 1500px) {
    .img {
        width: calc(20% - 15px);

    }
}

@media (max-width: 1200px) {
    .img {
        width: calc(25% - 15px);
    }

    .content {
        flex-direction: column;
        align-items: center;
    }

    .contents {
        width: 100%;
        overflow: hidden;
    }
}

@media (max-width: 900px) {
    .img {
        width: calc(33.33% - 15px);
    }
}

@media (max-width: 600px) {
    .img {
        width: calc(50% - 15px);
    }
}

@media (max-width: 400px) {
    .img {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .content {
        gap: 15px;
    }

    .contents {
        width: 90%;
    }
}

@media (max-width: 500px) {
    .contents {
        width: 100%;
        overflow: hidden;
    }

    .rainwater h1 {
        font-size: 1.5em;
    }

    .contents h2 {
        font-size: 1.2em;
    }

    .contents p {
        font-size: 0.9em;
    }
}
</style>